<template>
  <div class="w-full flex gap-1.5">
    <div class="w-full bg-zinc-300 dark:bg-zinc-900  rounded-[5px] inline-flex">
      <textarea
        v-model="userPrompt"
        class="h-auto min-h-[30px] max-h-[200px] bg-transparent text-slate-600 dark:text-slate-300 text-xs font-normal font-hind leading-snug w-full resize-none focus:outline-none p-1"
        placeholder="Type Something..."
        @input="adjustTextarea"
        @keyup.enter="sendPrompt"
        :disabled="isLoading"
        autofocus
      ></textarea>
    </div>
    <button
      :disabled="isLoading || !userPrompt.trim()"
      @click="sendPrompt"
      class="hover:text-purple-600 text-black dark:text-white font-bold py-2 px-2 rounded cursor-pointer"
    >
      <span v-if="isLoading" class="loading-dots"></span>
      <!-- Icono de enviar -->
      <ion-icon v-else name="send-outline"></ion-icon>
    </button>
  </div>
</template>


<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userPrompt: ''
    };
  },
  methods: {
    sendPrompt() {
      this.$emit('send', this.userPrompt);
      this.userPrompt = ''; // Clear the textarea after sending
    }
  }
};
</script>
