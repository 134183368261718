<template>
  <div v-if="visible" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
    <div class="relative w-full max-w-3xl mx-4 my-8 md:my-16 lg:my-24">
      <div class="bg-white p-8 rounded-lg max-h-screen overflow-y-auto h-full">
        <a src="https://mydigitalmind.ai/privacy" ><h2 class="text-2xl font-bold mb-4">Política de Privacidad</h2></a>
        <h3 class="text-xl font-semibold mt-4">Introducción</h3>
        <p>En MYDIGITALMIND ("nosotros" o "nuestra"), nos comprometemos a proteger su privacidad y sus datos personales. Este Aviso de Privacidad describe cómo recopilamos, utilizamos y protegemos su información cuando utiliza nuestra aplicación y servicios asociados con OAuth de Google.</p>
        
        <h3 class="text-xl font-semibold mt-4">Información que Recopilamos</h3>
        <p>Al utilizar nuestra aplicación con OAuth de Google, podemos recopilar la siguiente información:</p>
        <ul class="list-disc list-inside">
          <li>Permisos no sensibles:
            <ul class="list-disc list-inside ml-4">
              <li>../auth/userinfo.email: Ver su dirección de correo electrónico principal de la cuenta de Google.</li>
              <li>../auth/userinfo.profile: Ver su información personal, incluyendo cualquier información personal que haya hecho pública.</li>
            </ul>
          </li>
          <li>Permisos sensibles:
            <ul class="list-disc list-inside ml-4">
              <li>../auth/gmail.send: Permite enviar correos electrónicos en su nombre.</li>
              <li>../auth/calendar: Ver, editar, compartir y borrar permanentemente todos los calendarios a los que puede acceder mediante el Calendario de Google.</li>
            </ul>
          </li>
        </ul>

        <h3 class="text-xl font-semibold mt-4">Cómo Utilizamos su Información</h3>
        <p>La información que recopilamos a través de OAuth de Google se utiliza para los siguientes fines:</p>
        <ul class="list-disc list-inside">
          <li>Proveer y Mejorar nuestros Servicios: Utilizamos su información para autenticar su identidad y brindarle acceso a nuestras funciones y servicios.</li>
          <li>Personalización: Personalizamos su experiencia en nuestra aplicación basándonos en su información de perfil.</li>
          <li>Comunicación: Podemos enviarle notificaciones, actualizaciones y comunicaciones relacionadas con nuestro servicio.</li>
          <li>Análisis: Utilizamos datos de uso para analizar y mejorar la eficiencia y la experiencia del usuario en nuestra aplicación.</li>
        </ul>

        <h3 class="text-xl font-semibold mt-4">Compartición de Información</h3>
        <p>No compartimos su información personal con terceros, excepto en los siguientes casos:</p>
        <ul class="list-disc list-inside">
          <li>Con su Consentimiento: Podemos compartir su información con terceros si usted ha dado su consentimiento explícito.</li>
          <li>Cumplimiento Legal: Podemos divulgar su información si es requerido por la ley o en respuesta a una solicitud válida de autoridades gubernamentales.</li>
          <li>Proveedores de Servicio: Podemos compartir información con proveedores de servicio que nos ayudan a operar y mejorar nuestra aplicación.</li>
        </ul>

        <h3 class="text-xl font-semibold mt-4">Seguridad de su Información</h3>
        <p>Nos comprometemos a proteger su información y utilizamos diversas medidas de seguridad para asegurar la integridad y confidencialidad de sus datos. Sin embargo, no podemos garantizar la seguridad absoluta de la información transmitida a través de internet.</p>

        <h3 class="text-xl font-semibold mt-4">Sus Derechos</h3>
        <p>Usted tiene derecho a acceder o eliminar su información personal en cualquier momento. Para ejercer estos derechos, por favor contáctenos directamente a través de tony@mydigitalmind.ia.</p>

        <h3 class="text-xl font-semibold mt-4">Cambios a este Aviso de Privacidad</h3>
        <p>Nos reservamos el derecho de modificar este Aviso de Privacidad en cualquier momento. Le notificaremos sobre cualquier cambio publicando el nuevo Aviso de Privacidad en nuestra aplicación y actualizando la fecha de "Última actualización" en la parte superior de este documento.</p>

        <h3 class="text-xl font-semibold mt-4">Contacto</h3>
        <p>Si tiene alguna pregunta o inquietud sobre este Aviso de Privacidad, por favor contáctenos a través de tony@mydigitalmind.ia o visítenos en nuestra dirección física: CDMX, México.</p>

        <div class="text-right mt-4">
          <button @click="$emit('close')" class="bg-gray-800 text-white px-4 py-2 rounded-lg">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
