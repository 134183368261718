// src/api.js
import axios from 'axios';

const API_URL = 'https://mydigitalmind.ai/api/generate-audio';

export const generateAudio = async (text) => {
  try {
    console.log('Enviando solicitud a la API de generación de audio con el texto:', text);
    // Considera añadir una limitación de longitud de texto o división aquí si es necesario
    const response = await axios.post(API_URL, { text }, { responseType: 'blob' });
    console.log('Respuesta recibida de la API:', response);
    return response.data;
  } catch (error) {
    console.error('Error al generar el audio:', error);
    console.error('Detalles de la solicitud:', { text });
    throw error;
  }
};
