<template>
  <div class="h-svh">
    <div
      class="h-svh bg-cover bg-fixed bg-center flex flex-col bg-gradient-to-b from-white to-neutral-400 dark:from-slate-800 dark:to-MDM-900">
      <!-- Barra superior fija -->
      <div
        class="sticky top-0 z-10 flex w-full h-[60px] px-[11px] py-3 flex justify-between items-center backdrop-blur-sm dark:bg-transparent">
        <ion-icon name="arrow-back-outline" class="w-10 h-10 dark:text-white cursor-pointer hover:opacity-80"
          @click="goBack"></ion-icon>
        <img class="w-10 h-10 object-contain" src="@/assets/images/logo_MDM.png" />
        <div class="items-center">
          <audio-toggle @audio-toggle="toggleAudio"></audio-toggle>

        </div>
        
      </div>

      <!-- Contenedor del chat -->
      <div class="flex-1 flex flex-col overflow-hidden">
        <!-- Historial de mensajes -->
        <div class="flex-1 overflow-hidden ">
          <message-history :messages="messages" :isAudioEnabled="isAudioEnabled"></message-history>
        </div>

        <!-- Barra inferior fija -->
        <div
          class="w-full h-[60px] px-[11px] backdrop-blur-sm backdrop-brightness-150 dark:backdrop-brightness-50 dark:bg-transparent py-3 flex sticky inset-x-0 bottom-0 z-10 justify-center items-center gap-1.5">
          <voice-input @send="sendPrompt"></voice-input>
          <div class="relative">
            <button @click="toggleMenu" class="p-2 bg-transparent border-none cursor-pointer hover:opacity-80">
              <ion-icon name="attach-outline" class="w-6 h-6 dark:text-white"></ion-icon>
            </button>
            <div v-if="isMenuVisible"
              class="absolute bottom-full mb-2 bg-white dark:bg-gray-800 rounded shadow-lg p-2 flex flex-col space-y-2 z-20">
              <!-- Aquí se integra el ImageUploader -->
              <image-uploader @image-uploaded="handleImageUpload" :isButtonEnabled="true"></image-uploader>
              <button @click="attachDocument"
                class="flex items-center space-x-2 p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded">
                <ion-icon name="document-outline" class="w-5 h-5 dark:text-white"></ion-icon>
                <span class="dark:text-white">Documento</span>
              </button>
              <button @click="attachLocation"
                class="flex items-center space-x-2 p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded">
                <ion-icon name="location-outline" class="w-5 h-5 dark:text-white"></ion-icon>
                <span class="dark:text-white">Ubicación</span>
              </button>
              <button @click="attachContact"
                class="flex items-center space-x-2 p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded">
                <ion-icon name="person-outline" class="w-5 h-5 dark:text-white"></ion-icon>
                <span class="dark:text-white">Contacto</span>
              </button>
            </div>
          </div>
          <message-input :isLoading="isLoading" @send="sendPrompt"></message-input>
        </div>
      </div>
    </div>

  </div>
</template>



<script>
import MessageHistory from './MessageHistory.vue';
import MessageInput from './MessageInput.vue';
import VoiceInput from './VoiceInput.vue';
import ImageUploader from './ImageUploader.vue';
import AudioToggle from './AudioToggle.vue';

export default {
  components: {
    MessageHistory,
    MessageInput,
    VoiceInput,
    ImageUploader,
    AudioToggle
  },
  data() {
    return {
      messages: [],
      isLoading: false,
      isMenuVisible: false,
      isAudioEnabled: true,
      threadId: null,
      retryTimeoutId: null,
      startTime: null,
      maxDuration: 180000, // 3 minutos
      profileImageUrl: '@/assets/images/logo-BS.png',
    };
  },
  methods: {
    goBack() {
      window.history.back();
    },
    toggleAudio(isEnabled) {
      this.isAudioEnabled = isEnabled;
      console.log("Audio toggled: ", this.isAudioEnabled);
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
      console.log("Menu visibility: ", this.isMenuVisible);
    },
    attachDocument() {
      console.log("Attach document");
      this.isMenuVisible = false;
    },
    attachLocation() {
      console.log("Attach location");
      this.isMenuVisible = false;
    },
    attachContact() {
      console.log("Attach contact");
      this.isMenuVisible = false;
    },
    async handleImageUpload(file) {
      if (!file) {
        this.addMessage('No se seleccionó ningún archivo de imagen.', 'system');
        return;
      }
      const formData = new FormData();
      formData.append('image', file);
      this.isLoading = true;
      try {
        const response = await fetch('https://mydigitalmind.ai/api/upload-image', {
          method: 'POST',
          body: formData,
        });
        if (response.ok) {
          const data = await response.json();
          if (data.analysis_result) {
            this.addMessage(`Imagen cargada y codificada con éxito.`, 'system');
            this.addMessage(`Imagen: ${data.analysis_result}`, 'system');
          } else {
            this.addMessage('No se pudo obtener la imagen codificada del servidor.', 'system');
          }
        } else {
          this.addMessage(`Error al cargar la imagen: ${response.statusText}`, 'system');
        }
      } catch (error) {
        this.addMessage(`Error al cargar la imagen: ${error.message}`, 'system');
      } finally {
        this.isLoading = false;
        this.isMenuVisible = false; // Oculta el menú después de cargar la imagen
      }
    },
    async sendPrompt(userPrompt) {
      if (!userPrompt.trim()) {
        this.addMessage('Por favor, escribe algo antes de enviar.', 'system');
        return;
      }
      this.isLoading = true;
      if (!this.threadId) await this.startConversation();
      try {
        const payload = { thread_id: this.threadId, message: userPrompt };
        const response = await fetch('https://mydigitalmind.ai/api/chateo', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });
        if (response.ok) {
          const data = await response.json();
          this.startTime = Date.now();
          this.addMessage(userPrompt, 'user');
          this.checkRunStatus(data.run_id);
        } else {
          const errorText = await response.text();
          this.addMessage(`Hubo un problema con la respuesta del servidor: ${response.statusText} - ${errorText}`, 'system');
          this.isLoading = false;
        }
      } catch (error) {
        this.addMessage('Error al enviar el mensaje: ' + error.message, 'system');
        this.isLoading = false;
      }
    },
    async startConversation() {
      if (this.threadId) return;
      this.isLoading = true;
      try {
        const response = await fetch('https://mydigitalmind.ai/api/starteo', { method: 'GET' });
        if (response.ok) {
          const data = await response.json();
          this.threadId = data.thread_id;
        } else {
          this.addMessage('Error al iniciar la conversación: ' + response.statusText, 'system');
          this.isLoading = false;
        }
      } catch (error) {
        this.addMessage('Error al iniciar la conversación: ' + error.message, 'system');
        this.isLoading = false;
      }
    },
    addMessage(content, sender) {
      this.messages.push({ content, sender });
    },
    async checkRunStatus(runId) {
      if (!this.threadId || !runId) {
        this.addMessage("Falta información del hilo o de la ejecución.", 'system');
        this.isLoading = false;
        return;
      }
      const elapsedTime = Date.now() - this.startTime;
      if (elapsedTime < this.maxDuration) {
        try {
          const response = await fetch(`https://mydigitalmind.ai/api/checkeo`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ thread_id: this.threadId, run_id: runId }),
          });
          const data = await response.json();
          if (data.status === 'completed') {
            this.addMessage(data.response, 'system');
            this.isLoading = false;
            this.startTime = null;
          } else {
            this.retryTimeoutId = setTimeout(() => this.checkRunStatus(runId), 5000);
          }
        } catch (error) {
          this.addMessage(`Error al verificar el estado de la ejecución: ${error.message}`, 'system');
          this.isLoading = false;
        }
      } else {
        this.addMessage("La operación tardó demasiado y fue cancelada.", 'system');
        this.isLoading = false;
      }
    },
  },
};
</script>
