import { createRouter, createWebHistory } from 'vue-router';
import WelcomePage from '@/pages/WelcomePage';
import HomeView from '@/views/HomeView.vue';
import PromptComponent from '@/components/PromptComponent.vue';
/*import ContenedorViajes from '@/components/ContenedorViajes.vue';
import PromptComponent_BackUP from '@/components/PromptComponent_BackUP.vue';
import FaceScan from '@/components/FaceScan.vue'; // Importa el componente FaceScan
import QRScan from '@/components/QRScan.vue';
import MetaVerso from '@/components/MetaVerso.vue';*/
//import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import DirectoryLinks from '@/pages/DirectoryPage.vue';
import PrivacyPage from '@/pages/PrivacyPage.vue';

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: WelcomePage
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeView
  },

  /*{
    path: '/metaverso',
    name: 'Metaverso',
    component: MetaVerso
  },*/

  {
    path: '/contacto',
    name: 'Contacto',
    component: PromptComponent
  },
 /* {
    path: '/test',
    name: 'Test',
    component: PromptComponent_BackUP
  },
  {
    path: '/viajes/:id',
    name: 'DetalleViaje',
    component: ContenedorViajes,
    props: true
  },
  {
    path: '/FaceScan', // La nueva ruta para el componente FaceScan
    name: 'FaceScan',
    component: FaceScan
  },
  {
    path: '/QRScan', // La nueva ruta para el componente FaceScan
    name: 'QRScan',
    component: QRScan
  },*/

  {
    path: '/authorize', // La nueva ruta para el componente FaceScan
    name: 'authorize',
  },

  {
    path: '/privacy', // La nueva ruta para la visualización de la politica de privacidad
    name: 'privacy',
    component: PrivacyPage
  },
  {
    path: '/directorio', // La nueva ruta para la visualización de la politica de privacidad
    name: 'directorio',
    component: DirectoryLinks
  },


  // Considera agregar una ruta para listar todos los viajes, si es necesario
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  console.log('Navigating to:', to.fullPath);
  next();
});

router.afterEach((to) => {
  console.log('Navigated to:', to.fullPath);
});

export default router;
