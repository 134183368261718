<template>
  <div class="w-full h-full relative overflow-y-auto p-4 flex flex-col space-y-4">
    <div
      v-for="(item, index) in messages"
      :key="index"
      class="flex"
      :class="{ 'justify-start': item.sender === 'system', 'justify-end': item.sender !== 'system' }"
    >

      <div v-if="item.sender === 'user'" class="p-3 ml-5 rounded-md bg-gray-900 dark:bg-MDM-900 text-white max-w-[75%] break-words">
        <span v-html="renderMarkdown(item.content)"></span>
      </div>
      <div v-else class="mr-auto max-w-[75%]">
        <template v-if="isCodeMessage(item.content)">
          <p class="message p-3 rounded-md bg-white break-words" v-html="renderMarkdown(extractNonCode(item.content))"></p>
          <div class="bg-MDM-900 p-4 rounded-lg relative mt-4">
            <div class="flex items-center relative bg-gray-800 p-2 rounded-t-lg text-white justify-between font-bold">
              <p>Código</p>
              <button @click="copyToClipboard(extractCode(item.content))" class="copy-button bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded">
                Copiar código
              </button>
            </div>
            <pre class="message p-3 rounded-b-lg bg-black text-white break-words overflow-auto">
              <code class="block whitespace-pre-wrap">{{ extractCode(item.content) }}</code>
            </pre>
          </div>
        </template>
        <template v-else>
          <p class="message p-3 rounded-md bg-white dark:bg-slate-950 max-w-[75%] dark:text-white break-words prose lg:prose-xl" v-html="renderMarkdown(item.content)"></p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import markdownIt from 'markdown-it';
import { generateAudio } from "../api"; // Ajusta la ruta según la ubicación de tu archivo api.js

const md = new markdownIt();

export default {
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    isAudioEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentAudio: null,
    };
  },
  watch: {
    messages: {
      handler(newMessages = [], oldMessages = []) {
        this.$nextTick(() => {
          this.scrollToBottom();
          if (newMessages.length && oldMessages.length) {
            const latestMessage = newMessages[newMessages.length - 1];
            if (latestMessage && latestMessage.sender === "system") {
              this.playAudio(latestMessage.content);
            }
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    renderMarkdown(content) {
      return md.render(content);
    },
    scrollToBottom() {
      const container = this.$el;
      if (container) {
        container.scrollTop = container.scrollHeight;
      } else {
        console.error("El contenedor de mensajes no fue encontrado");
      }
    },
    async playAudio(text) {
      if (!this.isAudioEnabled) return;

      if (this.currentAudio && !this.currentAudio.paused) {
        this.currentAudio.pause();
        this.currentAudio.currentTime = 0;
      }
      try {
        const audioBlob = await generateAudio(text);
        console.log("Audio Blob:", audioBlob); // Depuración

        // Verifica si la respuesta es un HTML (indicando un posible error del servidor)
        if (audioBlob.type.includes('text/html')) {
          const reader = new FileReader();
          reader.onload = (event) => {
            console.error('Error: La respuesta de la API es HTML:', event.target.result);
          };
          reader.readAsText(audioBlob);
          return;
        }

        if (audioBlob.size === 0) {
          console.error("El archivo de audio está vacío");
          return;
        }

        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);

        audio.onloadeddata = () => {
          audio.play().catch((error) => {
            console.error("Error al intentar reproducir el audio:", error);
          });
        };

        audio.onerror = (e) => {
          console.error("Error al reproducir el audio:", e);
        };

        audio.onended = () => {
          console.log("Reproducción de audio completada");
        };
      } catch (error) {
        console.error("Error generando audio:", error);
        console.error("Error Details:", error.response || error.message); // Depuración adicional
      }
    },
    copyToClipboard(code) {
      navigator.clipboard.writeText(code)
        .then(() => alert('Código copiado al portapapeles'))
        .catch(err => console.error('Error al copiar: ', err));
    },
    isCodeMessage(content) {
      return /```[\s\S]*```/.test(content);
    },
    extractCode(content) {
      const match = content.match(/```[\s\S]*```/);
      return match ? match[0].replace(/```/g, '').trim() : '';
    },
    extractNonCode(content) {
      return content.replace(/```[\s\S]*```/, '').trim();
    }
  },
  mounted() {
    this.scrollToBottom();
  },
};
</script>

<style scoped>
pre.message {
  font-family: 'Courier New', Courier, monospace;
}
</style>
