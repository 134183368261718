<template>
  <div class="audio-toggle ">
    <button @click="toggleAudio">
      <ion-icon :name="isAudioEnabled ? 'volume-high-outline' : 'volume-mute-outline'" class="w-10 h-10 dark:text-white"></ion-icon>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAudioEnabled: true
    };
  },
  methods: {
    toggleAudio() {
      this.isAudioEnabled = !this.isAudioEnabled;
      this.$emit('audio-toggle', this.isAudioEnabled);
    }
  }
};
</script>
