<template>
  <div>
    <input
      type="file"
      id="file"
      @change="handleFileUpload"
      accept="image/*"
      class="hidden"
    />
    <button
      @click="triggerFileInput"
      :disabled="!isButtonEnabled"
      class="flex items-center space-x-2 p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded disabled:opacity-50"
    >
      <ion-icon name="image-outline" class="w-5 h-5 dark:text-white"></ion-icon>
      <span class="dark:text-white">Imagen</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    isButtonEnabled: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    goBack() {
      window.history.back();
    },
    triggerFileInput() {
      if (this.isButtonEnabled) {
        document.getElementById('file').click();
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.$emit("image-uploaded", file);
      }
    }
  }
};
</script>
