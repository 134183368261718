<template>
  <div class="voice-input">
    <button @click="toggleRecording" class="w-6 h-6 relative flex-col justify-start items-start inline-flex">
      <ion-icon :name="isRecording ? 'mic-off-outline' : 'mic-outline'" class="w-full h-full dark:text-white"></ion-icon>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRecording: false,
      recognition: null,
      latestMessage: '',
    };
  },
  methods: {
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    startRecording() {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      if (!SpeechRecognition) {
        console.error('La API de reconocimiento de voz no es soportada en este navegador.');
        return;
      }
      this.recognition = new SpeechRecognition();
      this.recognition.lang = 'es-ES';
      this.recognition.continuous = false;
      this.recognition.interimResults = false;

      this.recognition.onstart = () => {
        console.log('Comenzó la grabación');
        this.isRecording = true;
      };

      this.recognition.onresult = event => {
        const transcript = event.results[0][0].transcript;
        console.log('Resultado de la grabación: ', transcript);
        this.latestMessage = transcript; // Actualiza el mensaje más reciente
        this.$emit('send', transcript);
        this.stopRecording();
        this.speak(transcript); // Reproduce el mensaje grabado
      };

      this.recognition.onerror = event => {
        console.error('Error en la grabación: ', event.error);
        this.stopRecording();
      };

      this.recognition.onend = () => {
        console.log('Terminó la grabación');
        this.stopRecording();
      };

      try {
        this.recognition.start();
      } catch (error) {
        console.error('Error al iniciar el reconocimiento de voz: ', error);
        this.stopRecording();
      }
    },
    stopRecording() {
      if (this.recognition) {
        try {
          this.recognition.stop();
          console.log('Grabación detenida');
        } catch (error) {
          console.error('Error al detener la grabación: ', error);
        }
        this.recognition = null;
        this.isRecording = false;
      }
    },
    speak(message) {
      const voiceSettings = {
        text: message,
        model_id: 'your-model-id',
        voice_settings: {
          stability: 0.5,
          similarity_boost: 0.5,
          style: 0.5,
          use_speaker_boost: true
        },
        pronunciation_dictionary_locators: [
          {
            pronunciation_dictionary_id: 'your-dictionary-id',
            version_id: 'your-version-id'
          }
        ],
        seed: 123
      };

      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(voiceSettings)
      };

      fetch('https://api.elevenlabs.io/v1/text-to-speech/your-voice-id/stream', options)
        .then(response => response.json())
        .then(data => {
          // Aquí puedes manejar el stream de audio o jugarlo con un HTMLAudioElement
          console.log(data);
        })
        .catch(err => console.error(err));
    }
  }
};
</script>